<template>
  <div>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <span class="text-h5">{{ `${$t("Change Route")}` }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">{{ $t("Set New Visit Day") }} :</h6>
                  <v-autocomplete
                    v-model="selected.new_visitdays"
                    :items="weekdays"
                    item-text="label"
                    item-value="value"
                    filled
                    multiple
                    required
                    :rules="requiredRules"
                    readonly
                    class="readonly_component"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">{{ $t("Set Week") }} :</h6>
                  <v-autocomplete
                    v-model="selected.new_setted_weeks"
                    :items="weeks"
                    filled
                    multiple
                    required
                    :rules="requiredRules"
                    readonly
                    class="readonly_component"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">{{ $t("Set Periods") }} :</h6>
                  <v-autocomplete
                    v-model="selected.newPeriods"
                    :items="periodList"
                    filled
                    multiple
                    required
                    :rules="requiredRules"
                    readonly
                    class="readonly_component"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">{{ $t("Explain the reason") }} :</h6>
                  <v-textarea
                    v-model="selected.gpvComments"
                    class="gpv_comment_text readonly"
                    readonly
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ $t("Responsible OK / NOT OK") }}
                    <span v-if="isResponsableEditable" class="required ml-2"
                      >*</span
                    >
                    :
                  </h6>
                  <v-autocomplete
                    v-model="selected.responsableApprovalStatus"
                    :items="responsableApprovalStatusValues"
                    item-text="label"
                    item-value="value"
                    filled
                    required
                    :rules="isResponsableEditable ? requiredRules : []"
                    :readonly="!isResponsableEditable"
                    v-bind:class="
                      !isResponsableEditable ? 'readonly_component' : ''
                    "
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{
                      `${this.$t("salesforce.Parent")} ${this.$t("comments")}`
                    }}
                    <span v-if="isResponsableEditable" class="required ml-2"
                      >*</span
                    >
                    :
                  </h6>
                  <v-textarea
                    v-model="selected.responsableComments"
                    :readonly="!isResponsableEditable"
                    v-bind:class="
                      `gpv_comment_text ${
                        !isResponsableEditable ? 'readonly' : ''
                      }`
                    "
                    required
                    :rules="isResponsableEditable ? requiredRules : []"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="isShowAdminInputs">
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ `${this.$t("Final")} ${this.$t("Decision")}` }}
                    <span v-if="isAdminEditable" class="required ml-2">*</span>
                    :
                  </h6>
                  <v-autocomplete
                    v-model="selected.adminApprovalStatus"
                    :items="adminApprovalStatusValues"
                    item-text="label"
                    item-value="value"
                    filled
                    required
                    :rules="isAdminEditable ? requiredRules : []"
                    :readonly="!isAdminEditable"
                    v-bind:class="!isAdminEditable ? 'readonly_component' : ''"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="isShowAdminInputs">
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ `${this.$t("Final")} ${this.$t("comments")}` }}
                    <span v-if="isAdminEditable" class="required ml-2">*</span>
                    :
                  </h6>
                  <v-textarea
                    v-model="selected.adminComments"
                    :readonly="!isAdminEditable"
                    v-bind:class="
                      `gpv_comment_text ${!isAdminEditable ? 'readonly' : ''}`
                    "
                    required
                    :rules="isAdminEditable ? requiredRules : []"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isSaving"
              @click="onSaveClick(selected)"
              ref="btnSave"
              v-if="isShowSaveBtn"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ `${$t("Change Route")}` }}</v-card-title>
      <v-card-text>
        <div class="status_wrapper d-flex justify-content-center align-center">
          <b-card
            bg-variant="dark"
            v-bind:header="$t('Pending Responsible')"
            class="text-center mr-2"
            style="width:200px"
          >
            <b-card-text>{{ pendingCountResponsable }}</b-card-text>
          </b-card>
          <b-card
            bg-variant="dark"
            v-bind:header="$t('Pending Admin')"
            class="text-center ml-2"
            style="width:200px"
          >
            <b-card-text>{{ pendingCountAdmin }}</b-card-text>
          </b-card>
        </div>
        <v-row
          class="status_wrapper d-flex justify-content-center align-center"
        >
          <v-col cols="12" sm="12" md="12"> </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefroutes"
              id="datatable"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";

export default {
  name: "changeRoute",
  data: function() {
    return {
      filterlist_fetch_url: "pos/changeroute/getfilterlist",
      filterable_col_list: [
        "date",
        "routeId",
        "userId",
        "pos_detail",
        "newRouteId",
        "gpvComments",
        "currentPeriods",
        "currentWeeks",
        "currentDays",
        "newPeriods",
        "new_setted_weeks",
        "new_visitdays",
        "responsableComments",
        "adminComments",
        "responsableApprovalStatus",
        "adminApprovalStatus",
      ],
      filterCriteria: {
        date: {},
        routeId: {},
        userId: {},
        pos_detail: {},
        newRouteId: {},
        gpvComments: {},

        currentPeriods: {},
        currentWeeks: {},
        currentDays: {},
        newPeriods: {},
        new_setted_weeks: {},
        new_visitdays: {},

        responsableComments: {},
        adminComments: {},
        responsableApprovalStatus: {},
        adminApprovalStatus: {},
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      showEmpty: false,

      totaldata: 0,
      data: [],
      responsableApprovalStatusValues: [],
      adminApprovalStatusValues: [],
      weekdays: [],
      periodsCount: 13,
      weekCount: 4,
      weeks: [],
      periodList: [],
      pendingCountResponsable: 0,
      pendingCountAdmin: 0,

      /********** image dialog options **********/
      showImageDialog: false,
      selected_image: null,

      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      valid: true,
      requiredRules: [(v) => !!v || "Required"],
      isSaving: false,
    };
  },
  watch: {},
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "isSPV",
      "isManager",
      "isSubAdmin",
      "isAdmin",
      "user",
    ]),
    isResonsableRole() {
      return this.isSPV || this.isManager || this.isSubAdmin;
    },
    isAdminRole() {
      return this.isAdmin;
    },
    isResponsableEditable() {
      if (
        this.selected &&
        this.isResonsableRole &&
        this.selected.adminApprovalStatus !== "APPROVED"
      ) {
        return true;
      }
      return false;
    },
    isAdminEditable() {
      if (this.isAdminRole) {
        return true;
      }
      return false;
    },
    isShowAdminInputs() {
      if (this.selected) {
        if (this.isAdminRole) {
          return true;
        } else if (
          this.isResonsableRole &&
          this.selected.adminApprovalStatus !== "PENDING"
        ) {
          return true;
        }
      }
      return false;
    },
    isShowSaveBtn() {
      if (
        this.isResonsableRole &&
        this.selected.adminApprovalStatus === "APPROVED"
      ) {
        return false;
      }
      return true;
    },

    col_id() {
      return {
        key: "id",
        title: "ID",
        field: "id",
        align: "left",
        width: 100,
        sortBy: "desc",
      };
    },
    col_date() {
      return {
        key: "date",
        title: this.$t("Date"),
        field: "date",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["from"]}
                  value={this.filterCriteria["date"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["to"]}
                  value={this.filterCriteria["date"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "date")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_route() {
      return {
        key: "routeId",
        title: this.$t("route"),
        field: "routeId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route && row.route.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["routeId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`routeId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["routeId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "routeId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_user() {
      return {
        key: "userId",
        title: "GPV",
        field: "userId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{row.user && row.user.name + " " + row.user.surname}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["userId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`userId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["userId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "userId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "Id POS",
        field: "posId",
        align: "left",
        width: 100,
        sortBy: "",
      };
    },
    col_pos_detail() {
      return {
        key: "pos_detail",
        title: `POS ${this.$t("detail")}`,
        field: "pos_detail",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.pos_detail}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["pos_detail"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`pos_detail`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["pos_detail"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos_detail")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_newRoute() {
      return {
        key: "newRouteId",
        title: `${this.$t("newitem")} ${this.$t("route")}`,
        field: "newRouteId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.newRoute && row.newRoute.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["newRouteId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`newRouteId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["newRouteId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "newRouteId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_gpvComments() {
      return {
        key: "gpvComments",
        title: `GPV ${this.$t("Reason")}`,
        field: "gpvComments",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["gpvComments"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`gpvComments`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["gpvComments"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "gpvComments")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_current_periods() {
      return {
        key: "currentPeriods",
        title: `${this.$t("Current")} ${this.$t("periods")}`,
        field: "currentPeriods",
        align: "left",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.currentPeriodsLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            let periods = [];
            for (let i = 1; i <= this.periodsCount; i++) {
              periods.push(i.toString());
            }
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["currentPeriods"]}
                  dataList={periods}
                  filterCriteria={this.filterCriteria["currentPeriods"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "currentPeriods")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_current_weeks() {
      return {
        key: "currentWeeks",
        title: `${this.$t("Current")} ${this.$t("Week")}s`,
        field: "currentWeeks",
        align: "left",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.currentWeeksLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            let weeks = [];
            for (let i = 1; i <= this.weekCount; i++) {
              weeks.push(i.toString());
            }
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["currentWeeks"]}
                  dataList={weeks}
                  filterCriteria={this.filterCriteria["currentWeeks"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "currentWeeks")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_current_days() {
      return {
        key: "currentDays",
        title: `${this.$t("Current")} ${this.$t("Day")}s`,
        field: "currentDays",
        align: "left",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.currentDaysLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["currentDays"]}
                  dataList={this.weekdays}
                  filterCriteria={this.filterCriteria["currentDays"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "currentDays")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_new_periods() {
      return {
        key: "newPeriods",
        title: `${this.$t("newitem")} ${this.$t("periods")}`,
        field: "newPeriods",
        align: "left",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.newPeriodsLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            let periods = [];
            for (let i = 1; i <= this.periodsCount; i++) {
              periods.push(i.toString());
            }
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["newPeriods"]}
                  dataList={periods}
                  filterCriteria={this.filterCriteria["newPeriods"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "newPeriods")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_new_weeks() {
      return {
        key: "new_setted_weeks",
        title: `${this.$t("newitem")} ${this.$t("Week")}s`,
        field: "new_setted_weeks",
        align: "left",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.new_setted_weeks_label}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            let weeks = [];
            for (let i = 1; i <= this.weekCount; i++) {
              weeks.push(i.toString());
            }
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["new_setted_weeks"]}
                  dataList={weeks}
                  filterCriteria={this.filterCriteria["new_setted_weeks"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "new_setted_weeks")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_new_visitdays() {
      return {
        key: "new_visitdays",
        title: `${this.$t("newitem")} ${this.$t("Day")}s`,
        field: "new_visitdays",
        align: "left",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.new_visitdays_label}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["new_visitdays"]}
                  dataList={this.weekdays}
                  filterCriteria={this.filterCriteria["new_visitdays"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "new_visitdays")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_responsableApprovalStatus() {
      return {
        key: "responsableApprovalStatus",
        title: `${this.$t("salesforce.Parent")} OK`,
        field: "responsableApprovalStatus",
        align: "left",
        width: 180,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.responsableApprovalStatusLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["responsableApprovalStatus"]}
                  dataList={this.responsableApprovalStatusValues}
                  filterCriteria={
                    this.filterCriteria["responsableApprovalStatus"]
                  }
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "responsableApprovalStatus")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_responsableComments() {
      return {
        key: "responsableComments",
        title: `${this.$t("salesforce.Parent")} ${this.$t("comments")}`,
        field: "responsableComments",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["responsableComments"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`responsableComments`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["responsableComments"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "responsableComments")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_adminApprovalStatus() {
      return {
        key: "adminApprovalStatus",
        title: `${this.$t("Final")} ${this.$t("Decision")}`,
        field: "adminApprovalStatus",
        align: "left",
        width: 180,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.adminApprovalStatusLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["adminApprovalStatus"]}
                  dataList={this.adminApprovalStatusValues}
                  filterCriteria={this.filterCriteria["adminApprovalStatus"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "adminApprovalStatus")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_adminComments() {
      return {
        key: "adminComments",
        title: `${this.$t("Final")} ${this.$t("comments")}`,
        field: "adminComments",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["adminComments"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`adminComments`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["adminComments"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "adminComments")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    columns() {
      return [
        this.col_id,
        this.col_date,
        this.col_user,
        this.col_route,
        this.col_posId,
        this.col_pos_detail,
        this.col_newRoute,
        this.col_gpvComments,
        this.col_current_periods,
        this.col_current_weeks,
        this.col_current_days,
        this.col_new_periods,
        this.col_new_weeks,
        this.col_new_visitdays,
        this.col_responsableApprovalStatus,
        this.col_responsableComments,
        this.col_adminApprovalStatus,
        this.col_adminComments,
        {
          key: "actions",
          title: this.$t("edititem"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  on-click={() => this.onItemEdit(row)}
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          },
        },
      ];
    },
  },
  methods: {
    getImageUri(image) {
      return ApiService.getFileUri(image);
    },
    onItemShowImageDialog(item, image) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.selected_image = this.getImageUri(image ? image : null);
      this.showImageDialog = true;
    },
    closeImageDialog() {
      this.showImageDialog = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async downloadImage(selected, downloadFile) {
      try {
        const response = await ApiService.get(this.selected_image, {
          responseType: "arraybuffer",
        });
        download(response, "download.png");
      } catch (error) {
        // this.close();
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (cancelFilterKey === "idposbrand") {
        this.filterCriteria[cancelFilterKey] = null;
      } else {
        this.filterCriteria[cancelFilterKey] = {};
      }
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value,
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#datatable .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "pos/changeroute";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.data = resp.data;
        if (this.data.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaldata = resp.total;
        this.responsableApprovalStatusValues =
          resp.responsableApprovalStatusValues;
        this.adminApprovalStatusValues = resp.adminApprovalStatusValues;

        this.weekdays = resp.weekdays;
        this.periodsCount = resp.periodsCount;
        this.weekCount = resp.weekCount;
        this.periodList = [];
        for (let i = 1; i <= this.periodsCount; i++) this.periodList.push(i);
        this.weeks = [];
        for (let i = 1; i <= this.weekCount; i++) {
          this.weeks.push(i);
        }
        this.pendingCountResponsable = resp.pendingCountResponsable;
        this.pendingCountAdmin = resp.pendingCountAdmin;
        this.$store.dispatch("auth/getGlobalState");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    createSelectedModel(item) {
      console.log("createSelectedModel - ", item);
      let model = {
        id: _.get(item, "id"),
        newPeriods: _.get(item, "newPeriods"),
        new_setted_weeks: _.get(item, "new_setted_weeks"),
        new_visitdays: _.get(item, "new_visitdays"),
        gpvComments: _.get(item, "gpvComments"),
        responsableApprovalStatus: _.get(item, "responsableApprovalStatus"),
        responsableComments: _.get(item, "responsableComments"),
        adminApprovalStatus: _.get(item, "adminApprovalStatus"),
        adminComments: _.get(item, "adminComments"),
      };
      return model;
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        let body = _.omit(item, [
          "gpvComments",
          "newPeriods",
          "new_visitdays",
          "new_setted_weeks",
        ]);
        if (this.isResonsableRole) {
          body = _.omit(body, ["adminComments", "adminApprovalStatus"]);
        } else {
          body = _.omit(body, [
            "responsableComments",
            "responsableApprovalStatus",
          ]);
        }
        if (body.id) {
          try {
            await ApiService.put(`pos/changeroute/${body.id}`, body);
            logInfo("Éxito !");
            this.showEdit = false;
            this.$store.dispatch("auth/getGlobalState");
            this.getDataFromApi();
            this.isSaving = false;
          } catch (error) {
            this.isSaving = false;
          }
        }
      }
    },
    init() {
      this.getDataFromApi();
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style>
span.required {
  color: #f00;
  font-weight: bold;
}
.theme--light.v-text-field--filled.v-input--is-readonly.readonly_component
  > .v-input__control
  > .v-input__slot {
  background: #c3c3c3;
}
.status_wrapper {
  margin-top: 0;
  color: #000;
}
.status_wrapper .bg-dark {
  background-color: #9f9f9f !important;
}
</style>
